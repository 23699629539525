<template>
	<div>
		<p>欢迎使用水务气体后台管理系统，请选择左侧菜单使用</p>
	</div>
</template>

<script>
    export default {
        name: 'Welcome'
    }
</script>

<style scoped>

</style>
